import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AdSense } from "./utils/adSenseUtils";
import callCaf from "./utils/cafWrapper";
import { VerizonContent } from "../verizon/VerizonContent";
import { QueryParam } from "../commons/Constants";
import Logger from "../commons/Logger";

function AdSenseBlock({ landerConfig, page, queryConfig }) {
  let adsenseContentDivId = "ads";
  if (page === AdSense.page.RELATED_LINKS) {
    adsenseContentDivId = "relatedLinks";
  }

  let search;
  if (
    !landerConfig.lander.template.includes("PARK_WEB") &&
    queryConfig[QueryParam.SEARCH_BOX]
  ) {
    search = (
      <div id="searchbox">
        <FormattedMessage id="adsBlockedText" />
      </div>
    );
  }

  return (
    <>
      <div id={adsenseContentDivId}>
        <FormattedMessage id="adsBlockedText" />
      </div>
      {search}
    </>
  );
}

AdSenseBlock.propTypes = {
  page: PropTypes.string.isRequired,
  queryConfig: PropTypes.object.isRequired,
  landerConfig: PropTypes.object.isRequired,
};

export function AdContent({ landerConfig, queryConfig, page }) {
  const [cafSucces, setCafStatus] = useState(true);

  useEffect(() => {
    if (!landerConfig.isForcedVerizon) {
      callCaf(landerConfig, queryConfig, page, setCafStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    landerConfig.isForcedVerizon ||
    (!cafSucces && landerConfig.isVerizonEnabled)
  ) {
    const inTreatmentCohort =
      landerConfig?.experiment?.bucket === "pw_treatment";
    if (inTreatmentCohort) {
      try {
        window.location.assign(landerConfig.lander?.banner?.link);
      } catch (e) {
        Logger.error(
          "Missing valid redirect to SERP from" +
            landerConfig.domain.rootDomain +
            toString(e)
        );
      }
    } else {
      return <VerizonContent />;
    }
  } else if (cafSucces)
    return (
      <AdSenseBlock
        landerConfig={landerConfig}
        queryConfig={queryConfig}
        page={page}
      />
    );

  return <FormattedMessage id="adsBlockedText" />;
}

AdContent.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};
