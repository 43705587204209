import ReactHtmlParser from "html-react-parser";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Banner } from "../../banner/Banner";
import { QueryParam } from "../../commons/Constants";
import { isMobileBrowser } from "../../commons/HelperFunctions";
import Logger from "../../commons/Logger";
import useNoWrapFontStyle from "../../commons/useNoWrapFontStyle";
import { Settings } from "../../config";
import { AdContent } from "../AdContent";
import { FooterCashParking } from "../footer/FooterCashParking";
import { AdSense } from "../utils/adSenseUtils";
import { ArrowUIWrapper } from "./ArrowUIWrapper";
import LayoutStyle, { SimpleLayoutBackground } from "./LayoutStyle";

const SimpleLayout = ({ landerConfig, queryConfig, page, content }) => {
  let domain;

  // default is false for this template
  const domainName =
    landerConfig.lander.domainDisplayName || landerConfig.domain.rootDomain;

  if (
    typeof queryConfig[QueryParam.SHOW_DOMAIN] === "boolean"
      ? queryConfig[QueryParam.SHOW_DOMAIN]
      : landerConfig.lander.showDomain
  ) {
    domain = <Domain domain={domainName} />;
  }

  let adContainerId = "related_links";
  if (page === AdSense.page.SPONSORED_LISTINGS) {
    adContainerId = "sponsored_listings";
  }

  let headerElement;
  if (landerConfig.lander.headerHtml) {
    let decodedHtml = "";
    try {
      decodedHtml = window.atob(landerConfig.lander.headerHtml);
    } catch (e) {
      Logger.error("headerHtml value is not properly encoded" + toString(e));
    }
    headerElement = <HeaderHtmlElement headerHtml={decodedHtml} />;
  } else if (landerConfig.lander.headerText) {
    headerElement = (
      <HeaderTextElement headerText={landerConfig.lander.headerText} />
    );
  }

  const hideArrowUIWrapper = isMobileBrowser();

  const renderAdContent = () => (
    <div id={adContainerId}>
      <AdContent
        landerConfig={landerConfig}
        queryConfig={queryConfig}
        page={page}
      />
    </div>
  );

  return (
    <div id="simpleLayout">
      <Banner
        landerConfig={landerConfig}
        queryConfig={queryConfig}
        content={content}
      />
      <SimpleLayoutBackground template={landerConfig.lander.template} />
      <div id="contentLayout">
        <LayoutStyle template={landerConfig.lander.template} />

        {headerElement}
        {domain}
        {hideArrowUIWrapper ? (
          renderAdContent()
        ) : (
          <ArrowUIWrapper template={landerConfig.lander.template}>
            {renderAdContent()}
          </ArrowUIWrapper>
        )}
        <FooterCashParking
          landerConfig={landerConfig}
          queryConfig={queryConfig}
        />
      </div>
    </div>
  );
};

SimpleLayout.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};

export default SimpleLayout;

export function HeaderHtmlElement({ headerHtml }) {
  return <div id="headerElement">{ReactHtmlParser(headerHtml)}</div>;
}

export function HeaderTextElement({ headerText }) {
  return <div id="headerElement">{headerText}</div>;
}

export function Domain({ domain }) {
  const domainContainerRef = useRef(null);
  const fontStyle = useNoWrapFontStyle(
    domain,
    domainContainerRef,
    Settings.NORMAL_DOMAIN_LENGTH_LIMIT
  );

  return (
    <div ref={domainContainerRef}>
      <div style={fontStyle} id="domain">
        {domain}
      </div>
    </div>
  );
}

Domain.propTypes = {
  domain: PropTypes.string.isRequired,
};

HeaderHtmlElement.propTypes = {
  headerHtml: PropTypes.string.isRequired,
};

HeaderTextElement.propTypes = {
  headerText: PropTypes.string.isRequired,
};
